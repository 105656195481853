import React from 'react';
import Layout from '../components/layout';
import * as styles from './chiffres-references.module.scss';
import '../components/layout.scss';
import SEO from '../components/seo';
import Counter from '../components/Counter';
import boxoffice from '../images/partenaires/boxoffice.png';
import capgemini from '../images/partenaires/capgemini.png';
import cgr from '../images/partenaires/cgr.png';
import culturevelo from '../images/partenaires/culturevelo.png';
import deezer from '../images/partenaires/deezer.png';
import diversey from '../images/partenaires/diversey.png';
import eclatdeverre from '../images/partenaires/eclatdeverre.png';
import euromaster from '../images/partenaires/euromaster.png';
import fram from '../images/partenaires/fram.png';
import ikea from '../images/partenaires/ikea.png';
import kimaimesuive from '../images/partenaires/kimaimesuive.png';
import societegeneral from '../images/partenaires/societegenerale.png';

const ChiffresReferencesPages = () => (
  <Layout>
    <SEO title="Chiffres clés & Références" />
    <section className={styles.chiffresPartenaires}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="static-h1">Chiffres clés et Références</h1>
          </div>
        </div>
      </div>
      <div className="large-container">
        <div className="mb0 row text-center">
          <div className="col-sm-12">
            <h2>In-Print en quelques chiffres</h2>
          </div>
          <div className="col-sm-12">
            <div className={styles.numbers}>
              <div className="text-center">
                <div className={styles.keyNumber}>
                  <Counter to={18} speed={2500} />
                </div>
                <div className="card">
                  <p className="m0">années d'existence </p>
                </div>
              </div>
              <div className="text-center">
                <div className={styles.keyNumber}>
                  <Counter to={20} speed={2500} />
                </div>
                <div className="card">
                  <p className="m0">collaborateurs dans la Team</p>
                </div>
              </div>
              <div className="text-center">
                <div className={styles.keyNumber}>
                  <Counter to={2190} speed={2500} />
                </div>
                <div className="card">
                  <p className="m0">clients partenaires</p>
                </div>
              </div>
            </div>
            <div className={styles.numbers}>
              <div className="text-center">
                <div className={styles.keyNumber}>
                  <Counter to={5} speed={2500} />
                  <span> millions</span>
                </div>
                <div className="card">
                  <p className="m0">d'euros de CA en 2024</p>
                </div>
              </div>
              <div className="text-center">
                <div className={styles.keyNumber}>
                  <span>+ </span>
                  <Counter to={300} speed={2500} />
                  <span>%</span>
                </div>
                <div className="card">
                  <p className="m0">de croissance du CA Grand Format en 5 ans</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={`parallax ${styles.partnersContainer}`}>
      <div className="large-container">
        <div className="mb0 row text-center">
          <div className="col-lg-2" />
          <div className="col-lg-8">
            <div className="card">
              <h2 className="primary">Nos références</h2>
              <p className="secondary bold">
                Plus que des clients, des partenaires au quotidien !
              </p>
              <p>
                In-Print accompagne des entreprises de toutes tailles et de tous
                secteurs d’activité (industrie, BTP, formation, santé,
                immobilier, restauration, loisirs, transport, culturel, …), des
                Banques, des Associations, des Administrations et des
                Collectivités Locales ainsi que de nombreux professionnels des
                Arts graphiques et de la Communication.
              </p>
              <div className={styles.partners}>
                <div className={styles.partner}>
                  <a href="https://www.box.fr/" target="_blank">
                    <img src={boxoffice} />
                  </a>
                </div>
                <div className={styles.partner}>
                  <a href="https://www.capgemini.com/" target="_blank">
                    <img src={capgemini} />
                  </a>
                </div>
                <div className={styles.partner}>
                  <a href="https://www.cgrcinemas.fr/" target="_blank">
                    <img src={cgr} />
                  </a>
                </div>
                <div className={styles.partner}>
                  <a href="https://www.culturevelo.com/" target="_blank">
                    <img src={culturevelo} />
                  </a>
                </div>
                <div className={styles.partner}>
                  <a href="https://www.deezer.com/fr/" target="_blank">
                    <img src={deezer} />
                  </a>
                </div>
                <div className={styles.partner}>
                  <a href="https://www.diversey.com/" target="_blank">
                    <img src={diversey} />
                  </a>
                </div>
                <div className={styles.partner}>
                  <a href="http://www.eclatdeverre.com/" target="_blank">
                    <img src={eclatdeverre} />
                  </a>
                </div>
                <div className={styles.partner}>
                  <a href="https://www.euromaster.fr/" target="_blank">
                    <img src={euromaster} />
                  </a>
                </div>
                <div className={styles.partner}>
                  <a href="https://www.fram.fr/" target="_blank">
                    <img src={fram} />
                  </a>
                </div>
                <div className={styles.partner}>
                  <a href="https://www.ikea.com/" target="_blank">
                    <img src={ikea} />
                  </a>
                </div>
                <div className={styles.partner}>
                  <a href="https://www.kimaimemesuive.fr/" target="_blank">
                    <img src={kimaimesuive} />
                  </a>
                </div>
                <div className={styles.partner}>
                  <a href="https://www.societegenerale.fr/" target="_blank">
                    <img src={societegeneral} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ChiffresReferencesPages;
